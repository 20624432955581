.buttonContainer {
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
}

.all {
    --color: #06c;
}

.low {
    --color: #0c2;
}

.high {
    --color: #c00;
}

.middle {
    --color: #cc9c00;
}

.button {
    padding: 5px 15px;

    background: transparent;
    color: var(--color);

    border: 1px solid var(--color);
    border-radius: 15px;
    outline: none;

    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.button:active {
    background: var(--color);
    color: white;
}

.active {
    background: var(--color);
    color: white;
}

.affairs {
    display: flex;
    gap: 17px;
    flex-wrap: wrap;
    max-width: 450px;
}
