.label {
    display: flex;
    align-items: center;

    cursor: pointer;
}

/*input[type="checkbox"] - no*/

.checkbox {
    appearance: none;
    /*Свойство appearance изменяет внешний вид элемента интерфейса, при сохранении его функции.
       Если задать значение none, то чекбокс пропадет, но по нему, тем не менее,
       можно щелкать и состояние чекбокса будет меняться*/

    width: 18px;
    height: 18px;

    background-repeat: no-repeat;
    background-position: center center;

    /* background-size: 90% 90%; */
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 3px;
    border: 2px solid #06c;
    cursor: pointer;
}

.checkbox:checked {
    appearance: none;

    background-image: url('checked.svg');
    /*background: #99ff99;*/
}

.checkbox:focus {
    border-color: #0059b3;
}

.spanClassName {
    font-family: 'Montserrat', sans-serif;
    color: #06c;
}
