.stand {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    gap: 36px;
}
.error{
    border: 3px solid red;
}
