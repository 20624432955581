.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border: 1px;
    border-radius: 3px;
}

.disabled {
    background: #004D99;
    opacity: 0.5;
    color: #002E5C;
}

.secondary {
    border: 1px solid #0066CC;
    color: #0066CC;
}

.default {
    background: #0066CC;
    color: #FFFFFF;
}

.red {
    background: #CC1439;
    color: #FFFFFF;
}

.default:hover {
    background: #0080FF;
}

.red:hover {
    background: #FF1A47;
}

.secondary:hover {
    background: #E5F0FA;
    border: 1px solid #0066CC;
}


.default:active {
    background: #0059B3;
}

.secondary:active {
    background: #C9E5FF;
    border: 1px solid #0066CC;
    color: #0066CC;

}

.red:active {
    background: #B31232;
}

.button:disabled {
    background: #004D99;
}
