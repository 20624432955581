.tech {
    width: 50vw;
}

.developer {

}

.techHeader {
    width: 50vw;
}

.developerHeader {

}

.row {
    display: flex;
}

.rowHeader {
    display: flex;
}

.loading {

}