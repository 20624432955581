.affair {
    display: flex;
    align-items: center;
    width: 185px;

    border: 1.5px solid var(--color);
    border-radius: 5px;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 37px;
}

.closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 37px;

    background-color: transparent;
    border: none;
    border-left: 1.5px solid var(--color);

    font-weight: 900;
    cursor: pointer;
}
