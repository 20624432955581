.sendForm {
    display: flex;
    align-items: center;
    padding: 70px;
    gap: 44px
}

.textarea {
    width: 100%;
    background: #F5F7FB;
    box-shadow: 0px 5px 20px rgba(29, 33, 38, 0.03), 0px 1px 2px rgba(29, 33, 38, 0.1);
    border-radius: 20px;
    padding: 12px 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    opacity: 0.9;
    resize: none;
}

.button {
    background: #0066CC;
    border-radius: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 38px;
    outline: none;
    border: none;
}

.button:hover {

}

.button:active {

}
