.friendMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.friendImageAndText {
    display:flex;
    flex-direction: row;
}

.friendImageAndText img {
    width: 48px;
    height: 48px;
    margin-right: 7px;
}

.friendText {
    border-radius: 10px;
    background: #F5F7FB;
    box-shadow: 0px 5px 20px rgba(29, 33, 38, 0.03), 0px 1px 2px rgba(29, 33, 38, 0.1);
    padding: 7px 13px 8px 13px;
    position: relative;
}
.friendText:after {
    content: '';
    position: absolute;
    left: -15px;
    bottom: 0;
    border: 16px solid;
    border-color: transparent transparent #F5F7FB transparent;
}

.friendName {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.friendMessageText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.friendTime {
    width: 29px;
    height: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}
