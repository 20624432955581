.radio {
}

.radio::before {
}

.radio:checked::before {
}

.label {
}

.options {
  display: flex;
  flex-direction: column;
}
