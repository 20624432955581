.message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.imageAndText {
    display: flex;
    flex-direction: row-reverse;

}

.imageAndText img {
    width: 48px;
    height: 48px;
    margin-left: 7px;
}

.text {
    background: #0066CC;
    box-shadow: 0px 5px 20px rgba(29, 33, 38, 0.03), 0px 1px 2px rgba(29, 33, 38, 0.1);
    border-radius: 10px;
    padding: 7px 13px 8px 13px;
    position: relative;

}

.text:after {
    content: '';
    position: absolute;
    right: -15px;
    bottom: 0;
    border: 16px solid;
    border-color: transparent transparent #0066CC transparent;

}

.name {
    display: flex;
    flex-direction: row-reverse;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.messageText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.time {
    width: 26px;
    height: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}