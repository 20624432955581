.input:focus {
}

.input {
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 2px;
}

.inputWrapper {
}

.superInput {
}

.errorInput {
    border: 1px solid #CC1439;
}

.errorInput:focus {
}

.error {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #CC1439;

}
