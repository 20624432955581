.sidebar {
    z-index: 20;
    height:60px;
    position:fixed;
    transition: 0.2s ease-in;
    height: 100vh;
    width: 317px;
    left: -317px;
    top: 0;

    background: #fff;
}

.sidebar.open {
    position: fixed;
    transition: 0.2s ease-out;

    left: 0;
    box-shadow: 0 10px 40px rgba(29, 33, 38, 0.13),
    0 1px 2px rgba(29, 33, 38, 0.1);
}

.background {
    z-index: 10;
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.3);
}

.nav {
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 63px;
    padding-left: 70px;

    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
}

.nav a {
    color: black;
    text-decoration: none;
}

.nav a:hover {
    color: #0080ff;
}

.nav :global(a.active) {
    text-decoration-line: underline;
    color: #0059B2;
}
.close {
    position: absolute;
    height: 24px;
    width: 24px;
    right: 24px;
    top: 16px;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close img {
    height: 24px;
    width: 24px;
}
