.pagination {
    display: flex;
}

.text1 {

}

.text2 {

}
