.burgerMenuIcon {
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 70px 10px 70px;
    box-shadow: 0 10px 40px rgba(29, 33, 38, 0.13),
    0 1px 2px rgba(29, 33, 38, 0.1);
}

.header h1 {
}