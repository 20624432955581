.error404 {
    width: 451px;
}

.wrapper {
    align-items: center;
    display: flex;
    height: calc(100vh - var(--header_height));
    justify-content: center;
}
