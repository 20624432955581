.App {
    --header_height: 60px;
}

.hw {
    border-top: 1px solid #D9D9D9;
    padding: 70px;
}

.hwTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    padding: 107px 0 10px 70px;
}